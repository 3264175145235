import type { NotificationType } from '@ui/composables/useNotificationsUi'
import { useNotification } from '@base/composables/useNotifications'
import type {
  SubmitErrorReportBody,
  UsePlatformReport,
} from '@ecom/composables/usePlatformReport/usePlatformReport.types'
import type { NotificationComponentType } from '@base/stores/notifications.types'

function usePlatformReport(): UsePlatformReport {
  const pending = ref<boolean>(false)
  const error = ref<Error | string | null>(null)

  const { $i18n } = useNuxtApp()
  const { t } = $i18n
  const errorReportNotificationType = 'toast' as NotificationComponentType
  const { showNotification } = useNotification(errorReportNotificationType)
  const { queryMagento } = useMagentoRestApi()

  /**
   * Get predefined contact topics
   */
  const getTopics = async () => {
    if (import.meta.client) {
      pending.value = true
      error.value = null

      try {
        return await queryMagento('V1/contact/getHelpTopics')
      }
      catch (err) {
        error.value = err as string
        throw new Error(err as string)
      }
      finally {
        pending.value = false
      }
    }
    return null
  }

  const submitErrorReport = async (body: SubmitErrorReportBody) => {
    if (import.meta.client) {
      pending.value = true
      error.value = null

      try {
        const message = await queryMagento('V1/contact/submitErrorReport', {
          method: 'POST',
          body,
        })
        return message
      }
      catch (err) {
        console.error(err)
        showErrorReportNotification({
          type: 'error',
          message: t('something_went_wrong'),
        })
        error.value = t('validation.error_occurred')
      }
      finally {
        pending.value = false
      }
    }
    return null
  }

  const showErrorReportNotification = ({
    type,
    message,
  }: {
    type: NotificationType
    message: string
  }) => {
    showNotification({
      id: crypto.randomUUID(),
      type,
      message,
      closable: false,
      timeout: 3000,
    })
  }

  return {
    pending: readonly(pending),
    error: readonly(error),
    getTopics,
    submitErrorReport,
  }
}

export { usePlatformReport }
