<template>
  <div>
    <p
      v-if="props.title"
      class="mb-7 text-center text-2xl font-bold text-secondary"
    >
      {{ props.title }}
    </p>
    <p
      v-if="props.description"
      class="mb-5"
    >
      {{ props.description }}
    </p>
    <Form
      :disabled="pending"
      @submit="submitHandler"
    >
      <fieldset class="mb-6 text-blue-50">
        <UiFormFieldSelect
          id="topic"
          name="topic"
          :options="topics"
          :placeholder="t('ecom_select_type_of_report')"
          rules="required"
          :disabled="pending"
          required
        />
      </fieldset>

      <fieldset class="my-5 text-blue-50">
        <UiFormFieldCheckbox
          id="currentPageError"
          name="currentPageError"
          type="checkbox"
          container-tag="div"
          variant="primary"
          :container-class="['items-center']"
          :label-visible="true"
        >
          <span class="text-sm">{{
            t('ecom_select_if_an_error_occurred_on_the_current_page')
          }}</span>
        </UiFormFieldCheckbox>
      </fieldset>

      <fieldset class="my-5 text-blue-50">
        <UiFormFieldTextarea
          id="content"
          name="content"
          :placeholder="t('ecom_error_description')"
          rules="required"
          :resize="false"
          :rows="6"
          required
        />
      </fieldset>

      <fieldset class="mt-5 text-blue-50">
        <UiFormField
          id="customerEmail"
          type="email"
          name="customerEmail"
          autocomplete="email"
          :placeholder="
            t('type_in_your_email')
          "
          :value="isLoggedIn ? customerEmail : ''"
          rules="required|email"
          :disabled="(isLoggedIn && customerEmail) || pending"
          class="!py-3.5"
          required
        />
      </fieldset>

      <CustomerConsents
        v-if="!isLoggedIn"
        ref="ConsentsComponent"
        area="sales_order"
        checkbox-variant="primary"
        class="mt-2 text-sm text-blue-50"
        :container-class="['items-center']"
      />

      <div class="pt-4 sm:pt-9">
        <fieldset class="flex justify-end">
          <UiButton
            :loading="processing"
            :disabled="processing"
            variant="primary"
            type="submit"
            :slim="true"
            class="ml-auto"
          >
            {{ t('ecom_send_request') }}
          </UiButton>
        </fieldset>
      </div>
    </Form>
  </div>
</template>

<script lang="ts" setup>
import type { SubmissionHandler } from 'vee-validate'
import CustomerConsents from '@customer/components/CustomerConsents/CustomerConsents.vue'
import type { SubmitErrorReportBody } from '@ecom/composables/usePlatformReport/usePlatformReport.types'
import { usePlatformReport } from '@ecom/composables/usePlatformReport/usePlatformReport'
import { useCart } from '@ecom/composables/checkout/useCart/useCart'

const props = defineProps<{
  title?: string
  description?: string
}>()

const emit = defineEmits<{
  (e: 'processing', value: boolean): void
  (e: 'success'): void
  (e: 'error'): void
}>()

const Form = defineAsyncComponent(async () => {
  const { Form: FormComponent } = await import('vee-validate')
  return FormComponent
})

const { t } = useI18n()
const { isLoggedIn, customerData } = useCustomer()
const { pending, getTopics, submitErrorReport } = usePlatformReport()

const processing = ref(false)
const topics = ref<string[]>([])
const ConsentsComponent = ref<InstanceType<typeof CustomerConsents>>()

const submitHandler: SubmissionHandler<SubmitErrorReportBody> = async (
  values,
) => {
  if (!isLoggedIn.value) {
    ConsentsComponent.value?.validate()
    if (ConsentsComponent.value?.consents.length) {
      const isValid = await ConsentsComponent.value.validate()
      if (!isValid) {
        return
      }
    }
  }

  const { currentPageError, ...data } = values

  const payload = { ...data }

  const currentUrl = import.meta.client ? window?.location?.href ?? '' : ''

  Object.assign(payload, {
    url: currentPageError ? currentUrl : '',
  })

  const { cart, getCart } = useCart()

  try {
    processing.value = true
    emit('processing', processing.value)
    if (isLoggedIn.value && !cart.value) {
      await getCart()
    }
    const response = await submitErrorReport({
      ...payload,
      frontendJson: cart.value ? JSON.stringify(cart.value) : '',
    })

    if (response) {
      emit('success')
    }

    return response
  }
  catch (error) {
    console.error(error)
    emit('error')
  }
  finally {
    processing.value = false
    emit('processing', processing.value)
  }
}

const customerEmail = computed(() => customerData.value?.customer?.email ?? '')

onBeforeMount(async () => {
  if (import.meta.server) {
    return
  }
  const data = await getTopics()
  topics.value = (data as string[]) ?? []
})

onUnmounted(() => {
  topics.value = []
})
</script>
